import React, { useState, useEffect, useRef } from 'react';
import { post_api } from '../../../../../redux/api_funcs';
import { get_api_url } from '../../../../../utils/urls';
import Button from '../../../../util_components/button/index';

function SearchStudent({
  onStudentUpdate,
  handleStudentErrorMSG,
  addStudentBtn,
  btnClickAction,
  btnText,
  studentsToRemove = []
}) {
  const [loading, setLoading] = useState(false);
  const [searchStudent, setSearchStudent] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [studentData, setStudentData] = useState([]);
  const [showDropdown, setShowDropdown] = useState(true);
  const [isStudentSelected, setIsStudentSelected] = useState(false);
  const dropDownRef = useRef(null);

  // Debounce search input
  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      setDebouncedSearch(searchStudent);
    }, 500);

    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [searchStudent]);

  useEffect(() => {
    const getStudentList = async () => {
      if (debouncedSearch.length >= 2) {
        // At least 2 characters for students
        setLoading(true);
        const url = get_api_url('v1.0/users/search', false, false, true); // New API endpoint
        const payload = {
          filter: debouncedSearch,
          role: 'student'
        };
        try {
          const result = await post_api(url, payload, true, 'hridaya'); // hridaya token for auth
          const users = result.data.users.filter((user) => user.uuid); // Ensure users with valid UUIDs
          setStudentData(users);
          setLoading(false);
        } catch (e) {
          console.error(e);
          setLoading(false);
        }
      }
    };
    getStudentList();
  }, [debouncedSearch]);

  const onHandleInput = (value) => {
    handleStudentErrorMSG();
    setShowDropdown(true);
    setSearchStudent(value);
  };

  const handleStudentClick = (student) => {
    setShowDropdown(false);
    setSearchStudent(student.fname);
    onStudentUpdate(student.uuid, student);
    setIsStudentSelected(true);
  };

  // Outside Click close Drop Down
  const handleClickOutside = (event) => {
    if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    if (showDropdown) {
      window.addEventListener('click', handleClickOutside);
    } else {
      window.removeEventListener('click', handleClickOutside);
    }
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [showDropdown]);

  return (
    <>
      <div className="relative w-250px">
        <div className="mb-1">Student</div>
        <input
          style={{ margin: '0' }}
          type="text"
          placeholder="Search student"
          value={searchStudent}
          onChange={(e) => onHandleInput(e.target.value)}
        />
        {showDropdown && studentData.length > 0 && debouncedSearch !== '' ? (
          <div
            ref={dropDownRef}
            className="bg-white w-auto rounded-xl border-2 border-borderColor z-10 top-20 left-0 right-0 py-4 absolute flex flex-col items-start max-h-400px overflow-y-scroll overflow-x-hidden"
          >
            {loading ? (
              <div className="px-4">Searching...</div>
            ) : (
              studentData
                .filter(
                  (student) =>
                    student.fname.toLowerCase().includes(debouncedSearch.toLowerCase()) &&
                    studentsToRemove.findIndex((st) => st.uuid === student.uuid) === -1
                )
                .map((item, index) => (
                  <div
                    key={index}
                    onClick={() => handleStudentClick(item)}
                    className="px-4 w-full hover:bg-gray-200 cursor-pointer mb-1"
                  >
                    <p className="m-0">
                      {item.fname}&nbsp;{item.lname}
                    </p>
                    <p className="m-0 text-mytLightGray">{item.email}</p>
                  </div>
                ))
            )}
          </div>
        ) : null}
      </div>
      {addStudentBtn ? (
        <div
          style={{
            height: 43,
            position: 'relative',
            top: 25
          }}
        >
          <Button
            onClick={() => {
              btnClickAction(() => {
                setSearchStudent('');
                setIsStudentSelected(false);
              });
            }}
            disabled={!isStudentSelected}
          >
            {btnText || 'Add Student'}
          </Button>
        </div>
      ) : null}
    </>
  );
}

export default SearchStudent;
